import { createRouter, createWebHistory } from "vue-router"
import home from "../pages/home"
import picturePage from "../pages/picturePage"

const routes = [
    { path: "/", name: "home", component: home },
    { path: "/picturepage", name: "picturePage", component: picturePage }
    // 其他路由
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
