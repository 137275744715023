<template>
  <div class="box">
    <div class="pic"></div>
    <div class="content">
      <div class="title" :class="{ flash: isFlashing }" @click="goLeadership_care">
        <img src="../../public/slices/领导关怀@2x.png" alt="无">
        <p>领导关怀</p>
      </div>
      <div class="title" :class="{ flash: isFlashing }" @click="goHistory">
        <img src="../../public/slices/领导关怀@2x(1).png" alt="无">
        <p>历史沿革</p>
      </div>
      <div class="title" :class="{ flash: isFlashing }" @click="goPrevious_teams">
        <img src="../../public/slices/领导关怀@2x(2).png" alt="无">
        <p>历届班子</p>
      </div>
      <div class="title" :class="{ flash: isFlashing }" @click="goEvents">
        <img src="../../public/slices/领导关怀@2x(3).png" alt="无">
        <p>太原大事记</p>
      </div>
      <div class="title" :class="{ flash: isFlashing }" @click="goPerson_style">
        <img src="../../public/slices/领导关怀@2x(4).png" alt="无">
        <p>台站风貌</p>
      </div>
      <div class="title" :class="{ flash: isFlashing }" @click="goStaff">
        <img src="../../public/slices/领导关怀@2x(5).png" alt="无">
        <p>职工风采</p>
      </div>
    </div>
    <div class="footer"  @click="handleClick">点击按钮，播放相应内容</div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import router from "@/router";

const isFlashing = ref(false)

function handleClick(){
  isFlashing.value = true
  setTimeout(() => {
    isFlashing.value = false
  }, 500) // 动画时长
}

async function goLeadership_care() {
  await router.push({name:"picturePage",query:{data:"leadership_care"}})
}
async function goHistory() {
  await router.push({name:"picturePage",query:{data:"history"}})
}
async function goPrevious_teams() {
  await router.push({name:"picturePage",query:{data:"previous_teams"}})
}
async function goEvents() {
  await router.push({name:"picturePage",query:{data:"events"}})
}
async function goPerson_style() {
  await router.push({name:"picturePage",query:{data:"person_style"}})
}
async function goStaff() {
  await router.push({name:"picturePage",query:{data:"staff"}})
}

</script>

<style scoped>
@font-face {
  font-family: "myFont";
  src: url("../../public/font/black.otf");
}
.box{
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
}
.pic{
  background-image: url("../../public/slices/中国气象局_logo_百度搜索 1@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 30vh;
  height: 30vh;
}
.content{
  margin-top: 7vh;
  width: 50%;
  height: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15%; /* Set the gap between the items */
}
.title{
  width: 42%;
  height: 10vh;
  display: flex;
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  margin-bottom: 6vh;
  background-image: url("../../public/slices/菜单@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.5s, border-color 0.5s, border-width 0.5s;
  cursor: pointer; /* 使光标变成手指 */
}
.title p{
  background: linear-gradient(to bottom, #FFFFFF, rgb(169, 234, 252));
  -webkit-background-clip: text;
  color: transparent;
  font-family: myFont;
  font-size: 4vh;
  margin-left: 2%;
}

.footer{
  color: #FFFFFF;
  font-size: 2vh;
}
.flash {
  background-color: rgba(45, 183, 241, 0.5);
}
</style>
