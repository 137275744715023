<template>
  <div id="app">
    <div style="position: absolute;
               border: 2px solid #2F3C3D;
               width: 85vw;
               height: 90vh;
               margin-top: 5vh;
               margin-left: 7.5vw;
               pointer-events: none;"
    ></div>
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style>
#app{
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("../public/slices/背景@2x.png");
  background-color: #074857;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(1); /* 调整亮度，1是默认值，>1增加亮度，<1减少亮度 */
}
</style>
